// import _ from 'lodash'
import React, { useState } from 'react'
// import styled, { ThemeProvider } from 'styled-components'

// import theme, { grayscale } from '../../config/theme'

// import SEO from '../components/SEO'
// import Layout from '../components/Layout'
// import Body from '../elements/Body'

// import { setConfig, hot } from 'react-hot-loader'
// setConfig({ pureSFC: true })

// const IndexPage = () => (
//   <ThemeProvider theme={theme}>
//     <>
//       <SEO />
//       <Layout>
//         {_.map(grayscale, (color, i) => (
//           <Mountain
//             key={color}
//             fill={color}
//             x={_.random(-1, 1, true)}
//             y={_.random(-1, 1, true)}
//             z={i / grayscale.length}
//           />
//         ))}
//       </Layout>
//     </>
//   </ThemeProvider>
// )

// const Divider = styled.div`
//   position: absolute;
//   width: 100vw;
//   height: 100vh;
//   background-color: ${props => props.fill};
//   background: ${props => props.bg};
//   z-index: ${props => props.zIndex} svg {
//     fill: ${props => props.fill};
//   }
//   clip-path: ${props =>
//     props.clipPath || 'polygon(0 15%, 100% 25%, 100% 85%, 0 75%)'};
// `

// const DividerMiddle = styled(Divider)`
//   clip-path: polygon(0 15%, 100% 25%, 100% 85%, 0 75%);
// `

// const Mountain = ({ fill, x, y, z }) => {
//   let [xa, ya] = [x, y].map(v => Math.abs(x))
//   let [xz, yz] = [x, y].map(v => (Math.abs(x) + z) / 2)

//   let xm = x * 20
//   const xset = {
//     left: [8, 40, 60],
//     // left: [0, 0, 0],
//     right: [30, 100, 200],
//     // right: [0, 0, 0],
//   }[x < 0 ? 'left' : 'right']
//   const xs = xset.map(v => xa * v)
//   const ys = [100, 100 - Math.abs(xs[2] - xs[0]), 100]

//   const clipPath = _.zip(xs, ys).map(v => v.map(v => `${v}%`).join(' '))
//   return (
//     <Divider
//       fill={fill}
//       zIndex={z}
//       clipPath={`polygon(${clipPath.join(', ')})`}
//     />
//   )
// }

// export default hot(module)(IndexPage)

export default () => <div />
